<template>
  <div class="container h-100 py-5">
    <div class="row justify-content-center h-100 align-items-center">
      <div class="col-xl-6 col-md-6">
        <div class="auth-form card">
          <div class="card-header">
            <h4 class="card-title">Link a crypto account</h4>
          </div>
          <div class="card-body">
            <form
              @submit.prevent="addWallet"
              class="identity-upload"
            >
              <div class="form-row">
                <div class="form-group row align-items-center">
                  <p v-if="btcValid === false" class="col-12 text-primary">This is not a valid bitcoin address</p>
                  <div class="col-sm-4">
                    <label for="inputEmail3" class="col-form-label"
                      >Wallet Address
                      <br />
                      <small>Please double check this address</small>
                    </label>
                  </div>
                  <div class="col-sm-8">
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <label class="input-group-text bg-primary"
                          ><i class="mdi mdi-currency-btc fs-18 text-white"></i
                        ></label>
                      </div>
                      <input
                        type="text"
                        class="form-control text-right"
                        placeholder=""
                        @blur="validateAddress"
                        v-model="wallet.address"
                      />
                    </div>
                  </div>
                </div>
                <div class="form-group col-xl-12">
                  <label class="mr-sm-2">Wallet name </label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder=""
                    v-model="wallet.description"
                  />
                </div>

                <div class="text-center col-12">
                  <router-link
                    to="settings-crypto"
                    class="btn btn-primary mx-2"
                  >Back
                  </router-link>
                  <button
                    type="submit"
                    class="btn btn-success mx-2"
                    @click.prevent="addWallet"
                    :disabled="!wallet.description || !wallet.address || btcValid === false"
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import validate from 'bitcoin-address-validation';
import api from '@/api';

export default {
  data: () => ({
    wallet: {
      description: '',
      address: '',
    },
    loading: false,
    btcValid: true,
  }),
  methods: {
    validateAddress() {
      this.btcValid = validate(this.wallet.address);
    },
    addWallet() {
      this.$toast.clear();
      this.loading = true;
      api.wallet.create(this.wallet).then((res) => {
        this.$toast.success('Crypto Wallet Saved');
        this.$router.push({ path: '/settings-crypto' });
      }).catch((err) => {
        this.$toast.error(err);
      })
    },
  },
};
</script>

<style>
</style>
